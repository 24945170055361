* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  /* font-family: "Preahvihear", sans-serif */
}
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --colB1: #00abe4;
  --colB2: #00d2ff;
  --colB3: #2edaff;
  --colB4: #50e0ff;
  --colB5: #73e6ff;
  --colB6: #9bedff;
  --colB7: #0a7ea4;
  --colW1: #f4f9fd;
  --colW2: #e9f1fa;
  --colW3: #ffffff;
  --colG1: #005d5d;
  --colG2: #7654f6;
  --colG3: #304074;
  --colG4: #76c30e;
}
.selectedQuestion {
  background-color: var(--colG1) !important;
  color: var(--colW2);
}
.correctAns {
  background-color: #aad1cc !important;
  color: #166534 !important;
  font-weight: 800;
}
.wrongAns {
  background-color: #d9b1c0 !important;
  color: #991b1b !important;
  font-weight: 800;
}

.yourAnswer {
  background-color: var(--colB1) !important;
  color: var(--colW2);
}
.text {
  color: var(--colB5);
}
.navbarAnimation {
  animation: slider 0.5s ease-in-out;
}
@keyframes slider {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
  }
}
.navbarAnimationOut {
  animation: sliderOut 0.5s ease-in-out;
  top: 0;
}
@keyframes sliderOut {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0);
  }
}
.loader {
  border: 4px solid var(--colB1);
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderFixed {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  background-color: #00000099 !important;
}

.top {
  top: -120px;
}
.gradient1 {
  background-image: linear-gradient(
    to top right,
    var(--colB6),
    #beedf7,
    var(--colB6)
  );
}
.gradient2 {
  background-image: linear-gradient(
    to bottom right,
    var(--colB6),
    #beedf7,
    var(--colB6)
  );
}

.testShadow {
  text-shadow: #00d2ff8a 0px 5px 20px;
}
.sw {
  box-shadow: #000 5px 5px 10px;
}
.img {
  -webkit-filter: drop-shadow(5px 5px 15px #3040749f);
  filter: drop-shadow(5px 5px 15px #3040749f);
}
.shadow1{
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px ;
}
.shadow2{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.shadow3{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.shadow4{
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.inputMainField {
  animation: InputAnm 0.3s ease-in-out;
}
@keyframes InputAnm {
  0% {
    display: none !important;
  }

  100% {
    display: flex !important;
  }
}
.EditInput:focus {
  outline: none;
}
.EditInput {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  padding: 4px 10px;
}
.EditRed {
  border: 1px solid red;
}
.EditRed:focus {
  border: 1px solid red;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.3);
}
.EditBlue {
  border: 1px solid #b1b1b1 !important;
}
.EditBlue:focus {
  border: 1px solid #1890ff !important;
  box-shadow: 0 0 5px rgba(7, 62, 114, 0.302);
}
.EditBlue:hover {
  border: 1px solid #1890ff !important;
}
.select {
  background-color: rgba(0, 171, 228, 0.2) !important;
  font-weight: 700;
}
.scrollBar {
  margin: 10px;
  height: 85%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrollBarY {
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrollBarX {
  margin: 10px;
  height: 85%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.imgShadow {
  -webkit-filter: drop-shadow(5px 10px 10px #222);
  filter: drop-shadow(5px 10px 10px #222);
}

.accordion-content {
  overflow: hidden;
}

.accordion-content-enter {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.accordion-content-enter-active {
  max-height: 500px; /* adjust as needed */
  opacity: 1;
}

.accordion-content-exit {
  max-height: 500px; /* adjust as needed */
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.accordion-content-exit-active {
  max-height: 0;
  opacity: 0;
}
.kGckkd {
  background-color: var(--colW2) !important;
  padding-bottom: 10px;
}
.mHisf {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.roundedInput {
  border-radius: 100px !important;
}

.reg[type="radio"]:after {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  top: -6px;
  left: -3px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 4px solid #d1d3d1;
  cursor: pointer;
}

.reg[type="radio"]:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  top: -6px;
  left: -3px;
  position: relative;
  background-color: var(--colB1);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 4px solid #d1d3d1;
  cursor: pointer;
}

.searchInput::placeholder {
  font-size: 12px;
}
.searchInput {
  font-size: 12px;
  border: none;
  padding: 7px;
}
.searchInput:focus {
  border: none !important;
  outline: none !important;
}
.userMainDiv {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  gap: 20px;
  padding: 10px;
  position: relative;
  justify-items: start;
  justify-content: start;
  align-items: flex-start;
}
.userMainDivs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.item {
  flex: 1 1 270px;
  max-width: 320px;
  min-width: 200px;
  margin-bottom: 20px;
  background-color: var(--colW1);
  /* border: 1px solid #ccc; */
}
.wrap-text {
  word-break: break-word;
  white-space: normal;
}
.mainImage {
  width: 100%;
  height: 60vh;
  position: relative;
  background-image: url("./assets/image/studyimage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden; /* Ensures the gradient doesn't overflow */
}

.mainImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.3)
  ); /* Adjust opacity as needed */
}
.fontFamily {
  font-family: "Preahvihear", sans-serif !important;
}
.blueBg {
  background-color: #caeaf5 !important;
}
.redBg {
  background-color: #f9c6c6 !important;
}
.testQuestionSelected{
  background-color: var(--colG4) !important;
  color: #fff;
}

@media screen and (max-width: 1366px) {
  .userMainDivs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .item {
    flex: 1 1 250px;
    max-width: 300px;
    min-width: 180px;
    margin-bottom: 20px;
    background-color: var(--colW1);
    /* border: 1px solid #ccc; */
  }
  .mainImage {
    width: 100%;
    height: 60vh;
    background-image: url("./assets/image/studyimage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
}
@media screen and (max-width: 685px) {
  .userMainDivs {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  .mainImage {
    height: 40vh;
  }

  .item {
    flex: 1 1 170px;
    max-width: 220px;
    min-width: 100px;
    margin-bottom: 0px;
    background-color: #f0f0f0;
    /* border: 1px solid #ccc; */
  }
}
@media screen and (max-width: 370px) {
  .userMainDivs {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .item {
    flex: 1 1 150px;
    max-width: 200px;
    min-width: 90px;
    margin-bottom: 0px;
    background-color: #f0f0f0;
    /* border: 1px solid #ccc; */
  }
}
